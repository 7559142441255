// Header.js
import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import ReactTable from "react-table";
import Modal from 'react-modal';
import DateTimePicker from 'react-datetime-picker';
import Webcam from "react-webcam";
import 'react-html5-camera-photo/build/css/index.css';
import 'react-table/react-table.css';
import 'react-dropdown/style.css';
import bwipjs from 'bwip-js';
import ReactToPrint from "react-to-print";
import ProductPrintLabel from '../Products/ProductPrintLabel';
import matchSorter from 'match-sorter';
import moment from 'moment';
import BarcodeReader from 'react-barcode-reader'
import { CSVLink } from "react-csv";

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '400px',
    color                 : 'black'
  }
};

const printModalStyle = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    width                 : '500px',
    color                 : 'black'
  },
  overlay: {zIndex: 1000}
};

const cancelButton = {
  content : {
    float                 : 'right'  
  }
};

class ReceiverContent extends Component {

  constructor(props) {
      super(props);
      
      this.state = {
          upc_search: '',            
          receiver: [], 
          receiveritems: [],
          receiveritem: [],
          receivernotes: [],
          receiverotherskus: [],
          scanitem: [],
          additem: [],
          cl_id: this.props.clientId,
          rc_id: this.props.receiverId,
          pr_id: 0,
          scans: 0,
          new_prod_id:0,
          rc_number: '',
          rc_company: '',
          rc_status: '',
          rc_contact_name: '',
          rc_contact_phone: '',
          rc_contact_email: '',
          rc_note: '',
          rc_date_added: null,
          rc_date_updated: null,
          rc_date_expected: null,
          rc_date_inspected: null,
          rc_date_final: '',
          rc_total_items: '',
          rc_total_pallets: '',
          rc_total_boxes: '',
          rc_total_hours: '',
          rc_total_weight: '',
          rc_total_cost: '',
          rc_total_items_expected: '',
          rc_type: '',
          editModalIsOpen:false,
          importModalIsOpen:false,          
          hoursModalIsOpen: false,
          statusModalIsOpen: false,
          photoModalIsOpen: false,
          filesModalIsOpen: false,
          labelModalIsOpen: false,
          binModalIsOpen: false,
          qtyModalIsOpen: false,
          expModalIsOpen: false,
          editModalMessage:'',
          importModalMessage:'',          
          hoursModalMessage:'',
          statusModalMessage:'',
          photoModalMessage:'',
          filesModalMessage:'',
          attachments: [],
          missingitems: [],
          file: null,
          rc_hours: 0,
          rc_boxes: 0,
          rc_pallets: 0,          
          rn_note: '',
          printQty: 1,
          printUPC: '',
          printDesc:'',
          new_qty: 0,
          adj_qty: 0,
          lg_qty: 0,
          lg_pr_id: 0,
          lg_rc_id: 0,
          edit_rc_id: '',
          edit_rc_number: '',
          edit_rc_company: '',
          edit_rc_date_exp: new Date(),
          edit_rc_date_max: new Date(),
          edit_rc_date_receive: new Date(),
          edit_rc_date_inspect: new Date(),
          new_bin: '',
          new_bin_qty: 0,
          ri_id: 0,
          ri_qty: 0,
          disableAddButton: true,
          selectedFile: null,
          scanResponse: null,
          scannedItem: [],
          addItemData: null,
          userToken: localStorage.getItem('userToken'),
          activity_type: 'rc_id',
          activity_type_id: this.props.receiverId,           
          activity_note: '',
          activity_time: 0,
          activity_read: 0,
          activity_entry_date: new Date(),
          trojanSerial:'',
          us_id: localStorage.getItem('usId'),
          result: 'Start scanning ...',
          lastOtId: 0,
          lastSku: '',
          lastProductId: 0,
          productothers: [],
          otherSku: '',
          printQty: 1,
          printUPC: '',
          printDesc: '',
          printSize: 48,
          labelWidth: 6,
          labelHeight: 200,
          checked: '',
          dchecked:'',
          rchecked:'',
          statusemailchecked:'',
          ot_pr_id: 0,
      };

      this.handleInputChange = this.handleInputChange.bind(this);

      this.handleScan = this.handleScan.bind(this)
     
      this.openEditModal = this.openEditModal.bind(this);
      this.afterOpenEditModal = this.afterOpenEditModal.bind(this);
      this.closeEditModal = this.closeEditModal.bind(this);
      
      this.openImportModal = this.openImportModal.bind(this);
      this.afterOpenImportModal = this.afterOpenImportModal.bind(this);
      this.closeImportModal = this.closeImportModal.bind(this);
     
      this.openHoursModal = this.openHoursModal.bind(this);
      this.afterOpenHoursModal = this.afterOpenHoursModal.bind(this);
      this.closeHoursModal = this.closeHoursModal.bind(this);

      this.openStatusModal = this.openStatusModal.bind(this);
      this.afterOpenStatusModal = this.afterOpenStatusModal.bind(this);
      this.closeStatusModal = this.closeStatusModal.bind(this);

      this.openPhotoModal = this.openPhotoModal.bind(this);
      this.afterOpenPhotoModal = this.afterOpenPhotoModal.bind(this);
      this.closePhotoModal = this.closePhotoModal.bind(this);

      this.openFilesModal = this.openFilesModal.bind(this);
      this.afterOpenFilesModal = this.afterOpenFilesModal.bind(this);
      this.closeFilesModal = this.closeFilesModal.bind(this);

      this.openLabelModal = this.openLabelModal.bind(this);
      this.afterOpenLabelModal = this.afterOpenLabelModal.bind(this);
      this.closeLabelModal = this.closeLabelModal.bind(this);

      this.openBinModal = this.openBinModal.bind(this);
      this.afterOpenBinModal = this.afterOpenBinModal.bind(this);
      this.closeBinModal = this.closeBinModal.bind(this);

      this.openQtyModal = this.openQtyModal.bind(this);
      this.afterOpenQtyModal = this.afterOpenQtyModal.bind(this);
      this.closeQtyModal = this.closeQtyModal.bind(this);
      this.handleSubmitQty = this.handleSubmitQty.bind(this);
      this.logActivity = this.logActivity.bind(this);
      this.findTrojanSerial = this.findTrojanSerial.bind(this);

      this.openExpModal = this.openExpModal.bind(this);
      this.afterOpenExpModal = this.afterOpenExpModal.bind(this);
      this.closeExpModal = this.closeExpModal.bind(this);
      this.handleSubmitExp = this.handleSubmitExp.bind(this);
   
      this.findUPC = this.findUPC.bind(this);
      this.addItem = this.addItem.bind(this);
      this.handleFileUpload = this.handleFileUpload.bind(this);
      this.handleAttach = this.handleAttach.bind(this);
      this.handleSubmitDate = this.handleSubmitDate.bind(this);
      
      this.handleImportFileUpload = this.handleImportFileUpload.bind(this);
      this.processImportFileUpload = this.processImportFileUpload.bind(this);
      this.handlePhoto = this.handlePhoto.bind(this);
      this.handleDeleteItem = this.handleDeleteItem.bind(this);
      this.handleDeleteReceiver = this.handleDeleteReceiver.bind(this);
      this.handleDeleteReceiverItems = this.handleDeleteReceiverItems.bind(this);
      this.getReceiverItems = this.getReceiverItems.bind(this);
      this.getReceiverMissingItems = this.getReceiverMissingItems.bind(this);
      this.handleDeleteFile = this.handleDeleteFile.bind(this);
      this.getAttachments = this.getAttachments.bind(this);

      this.playGood = this.playGood.bind(this);
      this.playBad = this.playBad.bind(this);
      this.playComplete = this.playComplete.bind(this);
      this.playResult = this.playResult.bind(this);
      this.handleCheck = this.handleCheck.bind(this);
      this.handleDescCheck = this.handleDescCheck.bind(this);
      this.handleStatusEmailCheck = this.handleStatusEmailCheck.bind(this);
      this.handleSelect = this.handleSelect.bind(this);


  } 

  setRef = webcam => {
    this.webcam = webcam;
  };
 
  setEmail = () => {
    this.setState({rc_contact_email: this.state.receiver[0].rc_contact_email})
  }

  handleStatusEmailCheck = (event) => {     
      
    var curval = this.state.statusemailchecked;

    if (!curval) {        
        this.setState({statusemailchecked:true})
    } else {
        this.setState({statusemailchecked:false})
    }

  }

  handleRecountCheck = (event) => {     
      
    var curval = this.state.rchecked;

    if (!curval) {        
        this.setState({rchecked:true})
    } else {
        this.setState({rchecked:false})
    }

  }

  handleDescCheck = (event) => {     
      
    var curval = this.state.dchecked;

    if (!curval) {        
        this.setState({dchecked:true})
    } else {
        this.setState({dchecked:false})
    }

  }

  handlePhoto = (e) => {
    e.preventDefault();        
    
    const imageSrc = this.webcam.getScreenshot();

    //console.log(imageSrc);    

    var fetchURL = process.env.REACT_APP_NODE_ROOT_URL+'/files/receiver/photo/'+this.state.rc_id;

    var obj = { cl_id: this.state.cl_id, img: imageSrc };
    var myJSON = JSON.stringify(obj);

    this.setState({activity_note: 'New receiver attachment added.'});
    this.setState({activity_time: 1});

    //console.log(myJSON);
    
    fetch(fetchURL, {
        method: 'post',        
        body: myJSON,      
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'bwToken': this.state.userToken          
        }        
    
    }).then(function(response) {  
        return response.json();
    }).then(function(data) { 
        alert('Photo uploaded');
        window.location.reload();            
    }).then(this.logActivity).catch(function(error) {
        alert(error);        
    });
    
    e.target.value = null; 

  }

  getReceiver() {

    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/r/'+this.props.receiverId, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(receiver => this.setState({ receiver }))
      .then(this.setEmail);    
  }

  getReceiverItems() {
    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/'+this.props.receiverId, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(receiveritems => this.setState({ receiveritems }));
  }

  getReceiverNotes() {
    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/notes/'+this.props.receiverId, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(receivernotes => this.setState({ receivernotes }));
  }

  getReceiverMissingItems() {
    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/log/'+this.props.receiverId, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(missingitems => this.setState({ missingitems }));
  }

  getAttachments() {

    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/files/receiver/'+this.props.receiverId, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
    }).then(res => res.json())
      .then(attachments => this.setState({ attachments }));
   
  }

  getReceiverOtherSkus() {
    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/other/'+this.props.orderId,
    {
        method: 'GET',      
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }

        //TODO:
        //credentials: 'same-origin', // send cookies
        //credentials: 'include'   // send cookies, even in CORS
    
    }).then(res => res.json())
    .then(orderotherskus => this.setState({ orderotherskus }));
  }

  scanOther(cl_id,rc_id,pr_id,ri_id,ot_id,us_id,lg_date){

    var obj = { cl_id: cl_id, rc_id: rc_id, pr_id: pr_id, ri_id: ri_id, ot_id: ot_id, us_id: us_id, lg_date: lg_date };
    var myJSON = JSON.stringify(obj);
    console.log('scan other: ');
    console.log(myJSON);
    //return true;

    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/other/'+this.props.receiverId, {
        method: 'POST',        
        body: myJSON,      
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'bwToken': this.state.userToken      
        }
        //TODO:
        //credentials: 'same-origin', // send cookies
        //credentials: 'include'   // send cookies, even in CORS
    
    }).then(function(response) {  
        return response.json();
    }).then(function(data) { 
        console.log(data);            
    }).catch(function(error) {
        console.log('Other item scan not logged for item!' + error);        
    });
  }

  

    //LIFECYCLE METHODS
    //     
  componentDidUpdate(prevProps, prevState) {
    // console.log('componentDidUpdate fired');
    //console.log('scans:' + this.state.scans); 
  }

  componentDidMount() {
    //const values = queryString.parse(window.location.search);        
 
    this.getReceiver();
    this.getReceiverItems(); 
    this.getReceiverMissingItems();
    this.getReceiverNotes();
    
    //TODO: errors
    this.getAttachments();  
  
    Modal.setAppElement('#main');

  }

  //EDIT
  //
  openEditModal = (e) => {    

    this.setState({editModalIsOpen: true});    
    
    this.setState({edit_rc_id: this.state.receiver[0].rc_id});
    this.setState({edit_rc_number: this.state.receiver[0].rc_number});
    this.setState({edit_rc_company: this.state.receiver[0].rc_company});
    this.setState({edit_rc_date_exp: new Date(this.state.receiver[0].rc_date_expected)});
    this.setState({edit_rc_date_max: new Date(this.state.receiver[0].rc_date_final)});  
    this.setState({edit_rc_date_inspect: new Date(this.state.receiver[0].rc_date_inspected)});  
    this.setState({edit_rc_date_receive: new Date(this.state.receiver[0].rc_date_received)});  

  }
  
  afterOpenEditModal() {
  }

  closeEditModal() {
    this.setState({editModalIsOpen: false});
    this.handleSubmitDate();
  }

  //IMPORT
  openImportModal() {
    this.setState({importModalIsOpen: true});
  }

  afterOpenImportModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  closeImportModal() {
    this.setState({importModalIsOpen: false});
    this.handleSubmitDate();
  } 

  //Hours
  openHoursModal() {
    this.setState({hoursModalIsOpen: true});
  }

  afterOpenHoursModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  closeHoursModal() {
    this.setState({hoursModalIsOpen: false});
  }

  //Status
  openStatusModal() {
    //set dropdown values
     this.setState({rc_status: this.state.receiver[0].rc_status});
    //
    
    this.setState({statusModalIsOpen: true});
  }

  afterOpenStatusModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  closeStatusModal() {
    this.setState({statusModalIsOpen: false});
    this.handleSubmitDate();
  }

  //Photo
  openPhotoModal() {
    this.setState({photoModalIsOpen: true});
  }

  afterOpenPhotoModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  closePhotoModal() {
    this.setState({photoModalIsOpen: false});
    this.handleSubmitDate();
  }

  //Files
  openFilesModal() {
    this.setState({filesModalIsOpen: true});
  }

  afterOpenFilesModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  closeFilesModal() {
    this.setState({filesModalIsOpen: false});
    this.handleSubmitDate();

  }

  //LABEL
  openLabelModal = (e) => {
    //console.log('printid: ' + e.original.pr_id);

    this.setState({labelModalIsOpen: true});
    this.setState({pr_sku: e.original.pr_sku});
    this.setState({printUPC: e.original.pr_upc});
    this.setState({printQty: e.original.lg_scan_count})
    this.setState({printDesc: e.original.pr_desc});

  }

  afterOpenLabelModal = (e) => {

    try {
      // The return value is the canvas element
      let canvas = bwipjs.toCanvas('mycanvas', {
                bcid:        'code128',       // Barcode type
                text:        this.state.printUPC,    // Text to encode
                scale:       3,               // 3x scaling factor
                height:      10,              // Bar height, in millimeters
                includetext: true,            // Show human-readable text
                textxalign:  'center',        // Always good to set this
            });
    } catch (err) {
        // `e` may be a string or Error object
    }


  }

  closeLabelModal() {
    this.setState({labelModalIsOpen: false});
    this.setState({printQty:1});
  } 

  //BIN 
  //TODO: get current bin from product grid? Or get all bins based on PR ID???
  openBinModal = (e) => {
   
    this.setState({binModalIsOpen: true});
    //this.setState({current_bin: e.original.pr_bin});
  }

  afterOpenBinModal() {

  }

  closeBinModal() {
    this.setState({binModalIsOpen: false});
  }

  //QTY ADJUSTMENT
  openQtyModal = (e) => {
    //console.log('e-qty: ' + JSON.stringify(e));
    //var _obj = JSON.parse
    //console.log('rc_id: ' + e.original.rc_id );
    //console.log('pr_id: ' + e.original.pr_id );
    
    this.setState({lg_qty: e.original.lg_scan_count});
    this.setState({lg_rc_id: e.original.rc_id});
    this.setState({lg_pr_id: e.original.pr_id});
    //this.setState({pr_upc: e.original.pr_upc});
   
    this.setState({qtyModalIsOpen: true});
    //this.setState({pr_sku: e.original.pr_sku});
    //this.setState({printUPC: e.original.pr_upc});

  }

  afterOpenQtyModal() {
  }

  closeQtyModal() {
    this.setState({qtyModalIsOpen: false});
  }

  //EXP QTY ADJUSTMENT
  openExpModal = (e) => {
    console.log('exp-qty: ' + JSON.stringify(e));
    
    //console.log('ri_id: ' + e.original.ri_id );
    //console.log('ri_qty: ' + e.original.ri_qty );
    
    this.setState({ri_qty: e.original.ri_qty});
    this.setState({ri_id: e.original.ri_id});
    this.setState({pr_id: e.original.pr_id});
     
    this.setState({expModalIsOpen: true});

  }

  afterOpenExpModal() {
  }

  closeExpModal() {
    this.setState({expModalIsOpen: false});
  }

  playGood() {
    const audioEl = document.getElementsByClassName("good-audio-element")[0]
    audioEl.play()
  }

  playBad() {
    const audioEl = document.getElementsByClassName("bad-audio-element")[0]
    audioEl.play()
  }

  playComplete() {
    const audioEl = document.getElementsByClassName("complete-audio-element")[0]
    audioEl.play()
  }

  handleDeleteReceiver = () => {
    //e.preventDefault();

    var obj = { cl_id: this.props.clientId, rc_id: this.state.rc_id };
    var myJSON = JSON.stringify(obj);

    //console.log(myJSON);
    
    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/delete/'+this.props.clientId, {
        method: 'post',        
        body: myJSON,      
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'bwToken': this.state.userToken       
        }
        //TODO:
        //credentials: 'same-origin', // send cookies
        //credentials: 'include'   // send cookies, even in CORS
    
    }).then(function(response) {  
        return response.json();
    }).then(function(data) { 
        window.location.replace('/receivers'); 
    }).catch(function(error) {
        alert(error);        
    });

  } 

  //DELETE ITEM
  deleteItem = (prId) => {

    var obj = { cl_id: this.state.cl_id, rc_id: this.state.rc_id, pr_id: prId };
    var myJSON = JSON.stringify(obj);
    
    console.log(myJSON);

    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/item/del/'+this.state.cl_id, {
        method: 'POST',        
        body: myJSON,      
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'bwToken': this.state.userToken      
        }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {
          console.log(response);
      }).then(function(data) { 
          console.log(data);           
      }).catch(function(error) {
          alert('Receiver items could not be removed. Contact support.'); 
          console.log('submit err: ' + error);       
      });                
  }     
  
  handleDeleteReceiverItems = () => {

    let _receiveritems = this.state.receiveritems;

    const requests = _receiveritems.map((receiveritem, idx) => {

      var _prId = receiveritem.pr_id;
      var _riId = receiveritem.ri_id;

      var obj = { cl_id: this.state.cl_id, rc_id: this.state.rc_id, pr_id: _prId, ri_id: _riId };
      var myJSON = JSON.stringify(obj);
      
      console.log(myJSON);

      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/item/del/'+this.state.cl_id, {
          method: 'POST',        
          body: myJSON,
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken      
          }
            //TODO:
            //credentials: 'same-origin', // send cookies
            //credentials: 'include'   // send cookies, even in CORS
        
        }).then(function(response) {
            console.log(response);
        }).then(function(data) { 
            console.log(data);           
        }).catch(function(error) {
            //alert('Receiver items could not be removed. Contact support.'); 
            console.log('submit err: ' + JSON.stringify(error));       
        });            
      
    })

    // Wait for all requests, and then setState
    return Promise.all(requests).then(this.handleDeleteReceiver);     

  }

 // EVENT HANDLERS

  handleInputChange = (e) => {     
        
    const target = e.target;
    const value = target.value;
    const name = target.name;

    console.log('name: ' + name);
    console.log('value: ' + value);

    this.setState({
    [name]: value
    });

  }

  handleAttach = (e) => {
    e.preventDefault();    

    this.setState({filesModalMessage:'Uploading file...please wait.'})

    const myFile = document.getElementById('myFile');
    const data = new FormData();
    data.append('myFile', myFile.files[0]);

    var fetchURL = process.env.REACT_APP_NODE_ROOT_URL+'/files/receiver/'+this.state.rc_id;

    fetch(fetchURL, {
    method: 'POST',
    body: data,
    headers: {            
      'bwToken': this.state.userToken          
    }
    }).then(function(response) { 
            
            //console.log(response);
            //console.log(fetchURL);

            if (response.statusText === 'OK'){
              alert('File attached successfully.')
              //this.setState({filesModalMessage:'File uploaded successfully'});
            } else {
              alert('File attachment failed. Try again.')
              //this.setState({filesModalMessage:'Upload failed. Try again.'});
            }
            window.location.reload();
        }).then(function(data) { 
            //console.log(data);
        }).catch(function(error) {            
            alert('File not uploaded! ' + error);        
        });
    
    e.target.value = null; 
  }

  handleFileUpload = (event) => {
    this.setState({file: event.target.files});
  }

  handleImportFileUpload = (e) => {
    e.preventDefault(); 
    
    const myFile = document.getElementById('rcUpload');
    
    this.setState({
      selectedFile: myFile.files[0],
      loaded: 0,
    });
  }

  processImportFileUpload = (e) => {
    e.preventDefault();    
    
    //const myFile = document.getElementById('rcUpload');
    const data = new FormData();
    data.append('file', this.state.selectedFile);

    const isRecount = this.state.rchecked;
    var fetchURL = "";

    if (isRecount) {
      fetchURL = process.env.REACT_APP_NODE_ROOT_URL+'/upload/recount/'+this.props.clientId+'/'+this.props.receiverId;     
    } else {
      fetchURL = process.env.REACT_APP_NODE_ROOT_URL+'/upload/rc/'+this.props.clientId+'/'+this.props.receiverId;
    }
         
    fetch(fetchURL, {
    method: 'POST',
    body: data,
    headers: {            
      'bwToken': this.state.userToken          
    }
     }).then(function(response) {           
            return response.json();
        }).then(function(data) {
          console.log('upload: ' + JSON.stringify(data));
          alert('File uploaded. ' + JSON.stringify(data));          
        }).catch(function(error) {
            alert('File not uploaded! ' + error);        
        });
    
    e.target.value = null; 
  }

  handleSubmitHours = (e) => {
      //alert('Client name: ' + this.state.cl_name);
      e.preventDefault();

      //console.log('cl_id: ' + this.state.cl_id);
      var _thedate = moment().format("YYYY-MM-DD HH:mm:ss"); 
            
      var obj = { rc_id: parseInt(this.state.rc_id), rc_hours: this.state.rc_hours, rc_boxes: this.state.rc_boxes, rc_pallets: this.state.rc_pallets, rn_note: this.state.rn_note, us_id: this.state.us_id, in_date: _thedate };
      var myJSON = JSON.stringify(obj);

      console.log(myJSON);
      
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/hours/'+this.state.cl_id, {
          method: 'post',        
          body: myJSON,      
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken       
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {  
          return response.json();
      }).then(function(data) { 
          //console.log(data);
        alert("Hours added successfully");
        window.location.reload();         
      }).catch(function(error) {
          alert(error);        
      });

  }

  handleSubmitDate = () => {
    //alert('Client name: ' + this.state.cl_name);
   
    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/date/'+this.state.rc_id, {
        method: 'post',   
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'bwToken': this.state.userToken       
        }
        //TODO:
        //credentials: 'same-origin', // send cookies
        //credentials: 'include'   // send cookies, even in CORS
    
    }).then(function(response) {  
        //
    }).then(function(data) { 
      window.location.reload();        
    }).catch(function(error) {
        alert(error);        
    });

}

  handleSubmitEdit = (e) => {
      
      e.preventDefault();      
      
      var _expdate = moment(this.state.edit_rc_date_exp).format("YYYY-MM-DD HH:mm:ss"); 
      var _finaldate = moment(this.state.edit_rc_date_max).format("YYYY-MM-DD HH:mm:ss");
      var _inspectdate = moment(this.state.edit_rc_date_inspect).format("YYYY-MM-DD HH:mm:ss");
      var _receivedate = moment(this.state.edit_rc_date_receive).format("YYYY-MM-DD HH:mm:ss");
      var _editdate = moment().format("YYYY-MM-DD HH:mm:ss"); 

      var obj = { rc_id: parseInt(this.state.edit_rc_id), rc_number: this.state.edit_rc_number, rc_company: this.state.edit_rc_company, rc_date_expected: _expdate, rc_date_final: _finaldate, rc_date_updated: _editdate, rc_date_received: _receivedate, rc_date_inspected: _inspectdate };
      var myJSON = JSON.stringify(obj);

      //console.log('new json: ' + myJSON);          
            
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/edit/'+this.state.cl_id, {
          method: 'post',        
          body: myJSON,      
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken       
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {  
          return response.json();
      }).then(function(data) { 
        console.log(data);
        alert("Receiver details updated successfully");
        window.location.reload();         
      }).catch(function(error) {
          alert(error);        
      });      

  }

  logActivity = () => {
    console.log('log some stuff');

    var _entrydate = moment(this.state.activity_entry_date).format('YYYY-MM-DD HH:mm:ss')
    
    let sActivity = {
      in_type: this.state.activity_type,
      in_note: this.state.activity_note,
      is_read: this.state.activity_read,
      us_id: this.state.us_id,
      in_time: this.state.activity_time,
      in_entry_date: _entrydate,
      in_type_id: this.state.activity_type_id
    };
    
    var sActivityJSON = JSON.stringify(sActivity);

    console.log(sActivityJSON);
        
    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/timeclock/activity/'+this.state.cl_id, {
      method: 'POST',        
      body: sActivityJSON,      
      headers: {            
      'Content-Type': 'application/json',
      'bwToken': this.state.userToken          
      }
      //TODO:
      //credentials: 'same-origin', // send cookies
      //credentials: 'include'   // send cookies, even in CORS
  
    }).then(function(response) {
        if (response.status===200) {
          console.log('Activity added successfully');
          window.location.reload();                 
        }
    }).then(function(data) { 
        console.log(JSON.stringify(data))
    }).catch(function(error) {
        console.log('Activity could not be added'); 
        console.log('activity err: ' + error);       
    });
    
  }

  handleCheck = (event) => {     
      
    var curval = this.state.checked;

    if (!curval) {        
        this.setState({checked:true})
    } else {
        this.setState({checked:false})
    }

  }
  
  handleSelect = (event) => {
    var _val = event.target.value;
    //console.log('_val: ' + _val);

    this.setState({rc_status: _val})

    if (_val==='Received') {
      //console.log('status check please');
      this.setState({statusemailchecked:true});      
    } else {
      this.setState({statusemailchecked:false}); 
    }
  }

  handleSubmitQty = (e) => {
      
      e.preventDefault();
      this.refs.btnAdj.setAttribute("disabled", "disabled");

      let riqty =  parseInt(this.state.lg_qty)+parseInt(this.state.adj_qty);
           
      var _thedate = moment().format("YYYY-MM-DD HH:mm:ss"); 
      
      var obj = {cl_id: this.state.cl_id, rc_id: this.state.lg_rc_id, pr_id: this.state.lg_pr_id, ri_qty: parseInt(this.state.lg_qty)+parseInt(this.state.adj_qty), adj_qty: parseInt(this.state.adj_qty), us_id:this.state.us_id, in_date: _thedate };
      var myJSON = JSON.stringify(obj);

      console.log('handleSubmitQty: ' + myJSON);

      this.setState({activity_note: 'Received item qty changed from ' + this.state.lg_qty + ' to ' + riqty});
      this.setState({activity_time: 1});
            
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/qty/'+this.state.cl_id, {
          method: 'post',        
          body: myJSON,      
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken       
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {
          return response.json();
      }).then(function(data) { 
        console.log(data);
        alert("Qty updated successfully");
        //window.location.reload();         
      }).then(this.logActivity).catch(function(error) {
          alert(error);        
      });     
           
  }

  handleSubmitExp = (e) => {
      
    e.preventDefault();
    
    var obj = {ri_qty: this.state.ri_qty, pr_id: this.state.pr_id};
    var myJSON = JSON.stringify(obj);

    console.log('handleSubmitExp: ' + myJSON);
    
    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/exp/'+this.state.ri_id, {
        method: 'post',        
        body: myJSON,      
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'bwToken': this.state.userToken       
        }
        //TODO:
        //credentials: 'same-origin', // send cookies
        //credentials: 'include'   // send cookies, even in CORS
    
    }).then(function(response) {
        return response.json();
    }).then(function(data) { 
      console.log(data);
      alert("Expected Qty updated successfully");
      window.location.reload();         
    }).catch(function(error) {
        alert(error);        
    });

   
  }

  handleSubmitStatus = (e) => {
      
      e.preventDefault();
      
      if (this.state.rc_contact_email.indexOf(",")>0) {
        this.state.rc_contact_email = this.state.rc_contact_email.replace(',',';');
      }

      var obj = { rc_id: parseInt(this.state.rc_id), rc_note: this.state.rc_note, rc_status: this.state.rc_status, rc_contact_email: this.state.rc_contact_email, us_id: this.state.us_id, us_send_email: this.state.statusemailchecked, rc_number: this.state.receiver[0].rc_number };
      var myJSON = JSON.stringify(obj);

      
      console.log('test: ' + myJSON);
      
      fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/status/'+this.state.cl_id, {
          method: 'post',        
          body: myJSON,      
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken       
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {
          return response.json();
      }).then(function(data) { 
          //console.log(data);
        alert("Status updated successfully");
        window.location.reload();         
      }).catch(function(error) {
          alert(error);        
      });
      
      

  }

  handleDeleteFile = (filePath) => {
    
    //console.log('filePath: ' + filePath)  
    var obj = { filePath: filePath };
    var myJSON = JSON.stringify(obj);
    var fetchURL = process.env.REACT_APP_NODE_ROOT_URL+'/files/del/receiver/'+this.state.rc_id;

    fetch(fetchURL, {
      method: 'post',        
      body: myJSON,      
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'bwToken': this.state.userToken          
      }        
  
    }).then(function(response) {  
        return response.json();
    }).then(function(data) { 
        alert('File deleted');
        //window.location.reload();            
    }).then(this.getAttachments).catch(function(error) {
        alert(error);        
    });
     
  }
  
  handleDeleteItem = (e) => {
  
    var obj = { rc_id: e.original.rc_id, pr_id: e.original.pr_id, ri_id: e.original.ri_id };
    var myJSON = JSON.stringify(obj);

    console.log('handleDeleteItemJSON: ' + myJSON);
    
    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/item/del/'+this.state.cl_id, {
        method: 'post',        
        body: myJSON,      
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'bwToken': this.state.userToken       
        }
        //TODO:
        //credentials: 'same-origin', // send cookies
        //credentials: 'include'   // send cookies, even in CORS
    
    }).then(function(response) {
        return response.json();
    }).then(function(data) { 
        //console.log(data);
        //alert("Receiver Item deleted successfully");
      window.location.reload();         
    }).catch(function(error) {
        console.log(error);        
    });

  }

  runReport(event) { 
    console.log('run report');
  };

  handleScan(val){    
    this.setState({
      result: val,
    })
    
    if (this.state.cl_id == 22) {     
      this.parseTrojanUPC(val);
    } else {
      this.findUPC(val);
    }    

  }

  handleError(err){
    console.error(err)
  }


  parseTrojanUPC = (barcode) => { 
   
      var regex = /(?<=-).+/;
      //var strToMatch = "180/72K/41-2101007ST+JD201005-2";
      var otherSku = barcode.match(regex);
      console.log('otherSku:' + otherSku);
      otherSku = otherSku[0];
      
      if (otherSku !== '') {      
        
          console.log('matched: ' + otherSku);       
         
          //return true;
          var otherJSON = JSON.stringify({
            cl_id: this.state.cl_id,
            ot_sku: otherSku           
        });

        console.log('getSku: ' + otherJSON)

        return fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/other/sku/'+this.state.cl_id, {
          method: 'post',
          body: otherJSON,      
          headers: {            
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
        }).then(res => res.json())
        .then(trojanSerial => this.setState({ trojanSerial }))
        .then(this.findTrojanSerial).catch(function(error) {
          console.log(error);      
        });
        
      } else {
        alert('Trojan serial not found!')
        this.playBad();
        return false;
      }
      
    
  } 

  findTrojanSerial = () => {
    (async () => {
      let _product = this.state.trojanSerial;
      let _lastOtId = this.state.lastOtId;   
      console.log('_product: ' + JSON.stringify(_product));
      
      //return true;

      if (_product) {
        let _prid = _product[0].pr_id;
        let _otid = _product[0].ot_id;
             
        var otherJSON = JSON.stringify({
          cl_id: this.state.cl_id,
          pr_id: _prid,
          ot_id: _otid 
        });        
       
        console.log('prid: ' + _prid);
        console.log('otid: ' + _otid);
        this.setState({ot_pr_id: _prid});

        return fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/other/otid/'+_otid, {
          method: 'post',
          body: otherJSON,      
          headers: {            
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
        }).then(res => res.json())
        .then(otherSku => this.setState({ otherSku }))
        .then(this.findOtherSku).catch(function(error) {
          console.log(error);      
        });

      }
     
    })();   
  }

  
  findOtherSku = () => {
    
      let _product = this.state.trojanSerial;
      let _othersku = this.state.otherSku;

      console.log('_product: ' + JSON.stringify(_product));
      console.log('_othersku: ' + JSON.stringify(_othersku));

      //return false;

      /*
      _product: [{"ot_id":1,"cl_id":22,"ot_sku":"MJ10714","pr_id":70089,"pr_sku":"320/86ZZ/49B","pr_desc":"RT14531  320x86ZZx49B","pr_upc":"77461886670"}]
      ReceiverContent.js:1259 _othersku: [{"lg_ot_id":1}]
      */

      if (_othersku.length > 0) {
        let _otid = _othersku[0].lg_ot_id;

        if (window.confirm('This serial has already been received. Click OK if this a return.')) {         
          console.log('yes - this is a return');
          this.addItemTrojan(this.state.cl_id,this.state.rc_id,_product[0].pr_id,1,'In Process',_product[0].pr_upc,this.state.us_id);

          this.removeItemOtherScan(_otid);
          return true;

        }
        else {
          console.log('no - this is not a return');
          return false;
        }
        
      } else {

        if (_product) {        
          this.setState({lastOtId:_product[0].ot_id})
          this.findUPC(_product[0].pr_id);    
          return true;
        } else {
          alert('Trojan serial not found!')
          this.playBad();
          return false;
        }

      }
  }  

  removeItemOtherScan = (otID) => {

    fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/other/del/scan/'+otID, {
          method: 'post',             
          headers: {            
          'Content-Type': 'application/json',
          'bwToken': this.state.userToken          
          }
          //TODO:
          //credentials: 'same-origin', // send cookies
          //credentials: 'include'   // send cookies, even in CORS
      
      }).then(function(response) {
          //console.log(response);
      }).then(function(data) { 
          //console.log(data);
        window.location.reload();           
      }).catch(function(error) {
          alert('Other item scan could not be deleted. Contact support.'); 
          //console.log('submit err: ' + error);       
      });                
  }  

  findUPC = (upc) => { 

    let result = [];
    (async () => {
    
    if (this.state.cl_id == '22') {
      result = this.state.receiveritems.find( receiveritem => receiveritem.pr_id === upc);
    } else {
      result = this.state.receiveritems.find( receiveritem => receiveritem.pr_upc === upc);
    }

    //if found, then log scan
    if (result) {
      //copy array
      var newStateArray = this.state.receiveritems.slice();
          
      //get pr_id of scanned item
      var pr_id = result['pr_id'];      
      var ri_id = result['ri_id'];
      var ri_qty = result['ri_qty'];

      /* fix QOH
      var pr_qty = result['pr_qty']
      //console.log('pr_id: ' + pr_id);
      var _newqty = pr_qty+ri_qty;

      result['pr_qty'] = _newqty;
      */
      //get current scan cnt
      var curScans = result['lg_scan_count'];
      console.log('curScans: ' + curScans);

      //increment scan by 1
      var newScans = curScans+1;
      console.log('newScans: ' + newScans);

      //set value which will trigger render
      result['lg_scan_count'] = newScans;
   
      //get current diff cnt
      var expCount = result['ri_qty'];
      
      //do the math 1
      var newDiffScans = expCount-newScans;
     
      //set value which will trigger render
      result['qty_diff'] = newDiffScans;        
      
      //console.log('new result: ' + JSON.stringify(result));
      //get index for value swap
      //swap result row into new array at same position
      
      var ind = newStateArray.indexOf(result);
      
      //console.log('ind: ' + ind);

      //swap values with splice
      var replaced = newStateArray.splice(ind, 1, result);
      //console.log('newest arr: ' + JSON.stringify(newStateArray));
      let _up_date = moment().format("YYYY-MM-DD HH:mm:ss");

      if (this.state.cl_id == 22) {        
        this.scanOther(this.state.cl_id,this.state.rc_id,pr_id,ri_id,this.state.lastOtId,this.state.us_id,_up_date);
        this.scanItem(this.state.cl_id,this.state.rc_id,pr_id,this.state.us_id,_up_date);
      } else {
        this.scanItem(this.state.cl_id,this.state.rc_id,pr_id,this.state.us_id,_up_date);
      }
      
      this.setState({receiveritems: newStateArray});

      //TODO: how to color the row??
      ////this.colorRow(upc);

      //this.setState({upc_search: ''});
     
      this.setState({
        result: "updated - " + upc
      })

      this.playGood();
 
    } else {

      console.log('UPC not found on receiver! Try adding.. '); 
      //MAKE THIS WORK FOR RETURNS UGH
      
      if (this.state.cl_id == 22) {
        this.addItemTrojan(this.props.clientId,this.props.receiverId,upc,1,'In Process',upc,this.state.us_id);
        //let _up_date = moment().format("YYYY-MM-DD HH:mm:ss");        
        //this.scanOther(this.props.clientId,this.props.receiverId,upc,0,this.state.lastOtId,_up_date);
      } else {
        this.addItem(this.props.clientId,this.props.receiverId,0,1,'In Process',upc,this.state.us_id);
      }

    }
  })();
  
}

playResult = () => {
  console.log('sound to play: ' + this.state.addItemData)
}

addItem = (cl_id,rc_id,pr_id,ri_qty,ri_status,pr_upc,us_id) => {

  var obj = { cl_id: cl_id, rc_id: rc_id, pr_id: pr_id, ri_qty: ri_qty, ri_status: ri_status, pr_upc: pr_upc, us_id: us_id };
  var myJSON = JSON.stringify(obj);
  
  console.log('add item');
  console.log(myJSON);
  //return true;

  fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/item/'+rc_id, {
      method: 'post',        
      body: myJSON,      
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'bwToken': this.state.userToken          
      }
      //TODO:
      //credentials: 'same-origin', // send cookies
      //credentials: 'include'   // send cookies, even in CORS 

      //left off here

  }).then(function(response) {    
      return response.json();      
  }).then(function(data) {
    var _res = JSON.stringify(data);    
    
    if (_res ==='"missing"') {
      const audioEl = document.getElementsByClassName("bad-audio-element")[0]
      audioEl.play()
    } else {
      const audioEl = document.getElementsByClassName("good-audio-element")[0]
      audioEl.play()
    }    
    console.log('scan success: ' + _res)
  }).then(additem => this.setState({ additem })).then(this.getReceiverItems).then(this.getReceiverMissingItems).catch(function(error) {
      console.log('Scan not logged for item! Please re-scan: ' + error);    
  });
}

addItemTrojan = (cl_id,rc_id,pr_id,ri_qty,ri_status,pr_upc,us_id) => {

  
  var obj = { cl_id: cl_id, rc_id: rc_id, pr_id: pr_id, ri_qty: ri_qty, ri_status: ri_status, pr_upc: pr_upc, us_id: us_id };
  var myJSON = JSON.stringify(obj);
  
  console.log('add item - trojan');
  console.log(myJSON);
  //return true;

  fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/item/'+rc_id, {
      method: 'post',        
      body: myJSON,      
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'bwToken': this.state.userToken          
      }
      //TODO:
      //credentials: 'same-origin', // send cookies
      //credentials: 'include'   // send cookies, even in CORS 

      //left off here

  }).then(res => res.json())
    .then(additem => this.setState({ additem }))
    .then(this.scanItemTrojan)
    .then(this.getReceiverItems)
    .then(function(data) {
      var _res = JSON.stringify(data);    
      if (_res ==='"missing"') {
        const audioEl = document.getElementsByClassName("bad-audio-element")[0]
        audioEl.play()
      } else {
        const audioEl = document.getElementsByClassName("good-audio-element")[0]
        audioEl.play()
      }    
      console.log('scan success: ' + _res)
    })
    .catch(function(error) {
    console.log(error);      
  }); 
  
}

scanItemTrojan = () => {
  let _up_date = moment().format("YYYY-MM-DD HH:mm:ss");  
  let addedItem = this.state.additem;
  console.log('scanItemTrojan')
  console.log('TEST: ' + JSON.stringify(addedItem)) 
       
  //[{"cl_id":22,"rc_id":10,"ri_id":39,"pr_id":39921,"ri_qty":1,"pr_sku":"RT14587","pr_desc":"180x72Kx41","pr_upc":"77461801755","pr_ean":"","pr_price":0,
  //"pr_qty":8,"pr_volume":80,"pr_weight":0,"lg_scan_count":2,"qty_diff":-1,"cs_qty":null}]
  this.scanOther(this.props.clientId,this.props.receiverId,addedItem[0].pr_id,addedItem[0].ri_id,this.state.lastOtId,this.state.us_id,_up_date);
    
}

//TODO: LEFT OFF HERE .. modify routes/models/db
scanItem = (cl_id,rc_id,pr_id,us_id,lg_date) => {

  //TODO: add scan date in prep for movement log  
  // let scan_date = moment().format("YYYY-MM-DD HH:mm:ss");  
  var obj = { cl_id: cl_id, rc_id: rc_id, pr_id: pr_id, us_id: us_id, lg_date: lg_date };
  var myJSON = JSON.stringify(obj);
  
  console.log('scan item');
  console.log(myJSON);

  fetch(process.env.REACT_APP_NODE_ROOT_URL+'/receivers/scan/'+rc_id, {
      method: 'post',        
      body: myJSON,      
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'bwToken': this.state.userToken          
      }
      //TODO:
      //credentials: 'same-origin', // send cookies
      //credentials: 'include'   // send cookies, even in CORS
  
  }).then(function(response) {  
    return response.json();
  }).then(function(data) { 
      //console.log('scan success: ' + JSON.stringify(data));            
  }).then(this.getReceiverItems).catch(function(error) {
      alert('Scan not logged for item! Please re-scan: ' + error);
  });
}
  
  
  getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      return {
        style: {
          background: this.setRowColor(rowInfo.row.lg_scan_count,rowInfo.row.ri_qty),
          color: this.setTextColor(rowInfo.row.lg_scan_count,rowInfo.row.ri_qty),
        }
      }
    }
    return {};
  }

  setRowColor = (scanQty,itemQty) => {
      if (scanQty===itemQty) {
        return '#0F6';
      } else if (scanQty > itemQty) {
        return '#ffffa6';
      } else if (scanQty < itemQty) {
        return '#FFC1C1';
      } else {
        return 'lightblue';
      }     
  }

  setTextColor = (scanQty,itemQty) => {    
      return 'black';
  }

  render(){
        const { receiveritems } = this.state;
        const videoConstraints = {
          width: 1280,
          height: 720,
          facingMode: "user"
        };

        const statusOptions = [
          'Arrived', 'Canceled', 'Closed', 'Delivery Scheduled', 'In Process', 'On Hold', 'Open', 'Received' 
        ];

        const options = [
          { value: '0', label: 'Hours' },
          { value: '1', label: 'Items' },
          { value: '2', label: 'Flat Charge' }
        ];

        const _template = process.env.REACT_APP_ROOT_URL + '/files/receiver-import.csv';

        const headers = [
          { label: "Product ID", key: "pr_id" },
          { label: "SKU", key: "pr_sku" },
          { label: "Name", key: "pr_desc" },
          { label: "UPC", key: "pr_upc" },
          { label: "Current QOH", key: "pr_qty" },
          { label: "Received Item Qty", key: "lg_scan_count" },
          { label: "Received Case Qty", key: "cs_qty" },
          { label: "Expected Qty", key: "ri_qty" },
          { label: "Diff Qty", key: "qty_diff" }
          
        ];



        return (
<div id="main">
    <div className="page-wrapper">
               <div className="row page-titles">
                <div className="col-md-9">
                    <h3 className="text-primary">Receiver Details </h3>                           
                </div>
            </div>
            <div className="container-fluid">          
            
            <div className="card">  
              <div className="card-body" name="card-order-list">
              <div className="span-buttons">

                <button className="btn btn-info btn-sm" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                View Details
                </button>
                <button type="button" className="btn btn-info btn-sm" onClick={this.openHoursModal}>Add Hours</button>                
                <button type="button" className="btn btn-info btn-sm" onClick={this.openFilesModal}>Attach Files</button>
                <button type="button" className="btn btn-info btn-sm" onClick={this.openPhotoModal}>Capture Photo</button>
                <button type="button" className="btn btn-info btn-sm" onClick={this.openEditModal}>Edit Receiver</button>
                <button type="button" className="btn btn-info btn-sm" onClick={() => { if (window.confirm('Are you sure you want to delete this receiver?')) this.handleDeleteReceiverItems() } }>Delete Receiver</button>
                <button type="button" className="btn btn-info btn-sm" onClick={this.openImportModal}>Import Items</button>                  
                <button type="button" className="btn btn-info btn-sm" onClick={this.openStatusModal}>Update Status</button>   
                         
              </div>
              <div className="span-reader">
              <BarcodeReader
              onError={this.handleError}
              onScan={this.handleScan}/> 
              <p style={{fontWeight:"bold"}}>{this.state.result}</p>
              </div>
             
             
              </div>
              <div className="collapse" id="collapseExample">
                <div className="card card-body">
                  {this.state.receiver.map(receiverheader =>            
                    <div id="invoice-top" className="row" key={receiverheader.rc_id}>
                      <div className="col-sm-6">
                        Type: {receiverheader.rc_type} <br/>
                        Company: {receiverheader.rc_company} <br/>
                        Number: {receiverheader.rc_number} <br/>
                        Status: {receiverheader.rc_status} <br/>
                        Date Added: {moment(receiverheader.rc_date_added).local().format('MM-DD-YYYY hh:mm:ss a')} <br/>                       
                        Date Expected: {moment(receiverheader.rc_date_expected).local().format('MM-DD-YYYY hh:mm:ss a')} <br/>
                        Date Final Allowed: {moment(receiverheader.rc_date_final).local().format('MM-DD-YYYY hh:mm:ss a')} <br/>
                        Date Received: {moment(receiverheader.rc_date_received).local().format('MM-DD-YYYY hh:mm:ss a')} <br/>
                        Date Inspected: {moment(receiverheader.rc_date_inspected).local().format('MM-DD-YYYY hh:mm:ss a')} <br/>
                        Date Modified: {moment(receiverheader.rc_date_updated).local().format('MM-DD-YYYY hh:mm:ss a')}  <br/> 
                        <u>Notes</u>
                        <ul>
                        {this.state.receivernotes.map((note,idx) =>
                          <li key={idx}>{moment(note.rn_date).local().format('MM-DD-YYYY hh:mm:ss a')} - {note.rn_note} - {note.rn_nickname} </li>
                          )}
                        </ul> 
                        <u>Current attachments</u>
                        <ul>
                        {this.state.attachments.map((attachment,idx) =>
                          <li key={idx}><button type="button" className="btn btn-info btn-sm" onClick={() => { if (window.confirm('Are you sure you want to delete this file?')) this.handleDeleteFile(attachment)}}>x</button> <a href={"https://bw-admin-files.s3.amazonaws.com/".concat(attachment)} target="_new">{attachment.substr(attachment.lastIndexOf('/') + 1)}</a></li>
                        )}
                      
                        </ul> 
                      </div>
                      <div className="col-sm-6">
                        Total Items: {receiverheader.rc_total_items} <br/>
                        Total Weight: {receiverheader.rc_total_weight} <br/>                        
                        Total Hours Worked: {receiverheader.rc_total_hours} <br/>
                        Total Boxes: {receiverheader.rc_total_boxes} <br/>
                        Total Pallets: {receiverheader.rc_total_pallets} <br/>                        
                        Notifications Sent To: {receiverheader.rc_contact_name} {receiverheader.rc_contact_email} <br/>
                        Contact Phone: {receiverheader.rc_contact_phone} <br/>                       

                      </div>
                      
                    </div>
                    
                  )}
                </div>
              </div>            
              
            </div>   
            <div className="row">   
                    <div className="col-lg-4">
                    <u>Missing Items on import and scan</u>
                        <ul>
                        {this.state.missingitems.map((missing,idx) =>
                          <li key={idx}>{missing.rm_sku} ({missing.rm_cnt})</li>
                          )}
                        </ul> 
                    </div>
                </div>
                <div className="row">  
                    <div className="col-lg-12">
                       
                        <div className="card">
                        {this.state.receiver.map(receiverheader => 
                        <div key={receiverheader.rc_id}>
                        {receiverheader.rc_recount=='Yes' ? ( 
                          <div>
                          <h4><strong>This receiver was used to recount inventory. DO NOT DELETE.</strong></h4>                                  
                          </div>
                        ) : (<div></div>)}
                        </div>
                        )}
                           <div className="card-body" name="card-receiveritem-list">
                           <CSVLink headers={headers} data={receiveritems}>Download CSV of Receiver Items</CSVLink>
                                <div className="table-responsive">                                  

                                    <ReactTable

                                      data={receiveritems}
                                      filterable
                                      defaultFilterMethod={(filter, row) =>
                                      String(row[filter.id]) === filter.value}          
                                      columns={[
                                        {                                          
                                          columns: [
                                            {
                                              Header: "ri_id",
                                              accessor: "ri_id",
                                              width: 50,
                                              show: false,
                                              filterable: false
                                            },
                                            {
                                              Header: "Print",
                                              accessor: "pr_id",
                                              width: 50,
                                              filterable: false,
                                              Cell: props => (
                                                <div><button onClick={(e) => this.openLabelModal(props)} className="btn btn-sm">print</button></div>                                                
                                              )
                                            },
                                            {
                                              Header: "Bin",
                                              width: 50,
                                              show: false,                                              
                                              filterable: false,                                              
                                              Cell: props => (
                                                <div style={{ textAlign: "center" }}><i onClick={(e) => this.openBinModal(props)} className="fas fa-inbox" title="quick bin"></i></div>
                                              )
                                            },
                                            {
                                              Header: "SKU",
                                              accessor: "pr_sku",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },                                            
                                            {
                                              Header: "Name",
                                              accessor: "pr_desc",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            },                                            
                                            {
                                              Header: "UPC",
                                              accessor: "pr_upc",
                                              filterMethod: (filter, rows) =>
                                              matchSorter(rows, filter.value, { keys: ["pr_upc"] }),
                                              filterAll: true                                              
                                            },
                                            {
                                              Header: "Other SKU",
                                              accessor: "ot_sku",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },
                                            {
                                              Header: "EAN",
                                              accessor: "pr_ean",
                                              show: this.state.cl_id==22,
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)
                                            },
                                            {
                                              Header: "QOH",
                                              accessor: "pr_qty",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),                                              
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },                                            
                                            {
                                              Header: "QTY REC",
                                              accessor: "lg_scan_count",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: props => (
                                                <div style={{ textAlign: "center" }}><a href="#main" onClick={(e) => this.openQtyModal(props)}><u>{props.original.lg_scan_count}</u></a></div>
                                              )
                                            },    
                                            {
                                              Header: "CASES REC",
                                              accessor: "cs_qty",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),                                              
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                              )
                                            },                                      
                                            {
                                              Header: "QTY EXP",
                                              accessor: "ri_qty",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                                Cell: props => (
                                                  <div style={{ textAlign: "center" }}><a href="#main" onClick={(e) => this.openExpModal(props)}><u>{props.original.ri_qty}</u></a></div>
                                              )
                                            },
                                            {
                                              Header: "QTY DIFF",
                                              accessor: "qty_diff",
                                              filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value),
                                              Cell: row => (
                                                <div style={{ textAlign: "center" }}>{Math.abs(row.value)}</div>
                                              )
                                            },
                                            {
                                              Header: "Delete",
                                              accessor: "pr_id",
                                              width: 50,                                             
                                              filterable: false, 
                                              Cell: props => (
                                                <div><button onClick={() => { if (window.confirm('Are you sure you want to delete this receiver item?')) this.handleDeleteItem(props) } } className="btn btn-sm">X</button></div>   
                                              )
                                            },
                                          ]
                                        }
                                      ]}
                                      defaultPageSize={100}
                                      className="-striped -highlight"
                                      getTrProps={this.getTrProps}
                                      getTdProps={(state, rowInfo, column, instance) => {
                                        return {                                          
                                          onClick: (e, handleOriginal) => {
                                            if (rowInfo) {
                                              //console.log('r: '+rowInfo.rwo);
                                              //window.location.href = "/receiver/"+rowInfo.row.pr_id;
                                            }

                                            // IMPORTANT! React-Table uses onClick internally to trigger
                                            // events like expanding SubComponents and pivots.
                                            // By default a custom 'onClick' handler will override this functionality.
                                            // If you want to fire the original onClick handler, call the
                                            // 'handleOriginal' function.
                                            if (handleOriginal) {
                                              handleOriginal();
                                            }
                                          }
                                        };
                                      }}
                                    />

                                </div>
                            </div>

                        </div>
                    </div>
                </div>               
             
            </div>
         </div>

         <Modal
            isOpen={this.state.importModalIsOpen}
            onAfterOpen={this.afterOpenImportModal}            
            style={customStyles}
            contentLabel="Import Receiver Items"
          >                   
         
            <div className="container-fluid">               
              <form>
              <h2>Import Receiver Items</h2>
                  <div className="row">
                    <div className="col-sm-12">                                      
                      Select and import a file. <br/>
                       <input type="file" id="rcUpload" name="rcUpload" accept=".csv" onChange={(e) => this.handleImportFileUpload(e)} />                                                                 
                       <label id="rcUploadResponse"></label>
                      
                    </div>                                        
                  </div>
              <div className="row">
              <div className="col-sm-12"> 
              <button type="submit" onClick={(e) => this.processImportFileUpload(e)}>Import</button>&nbsp;&nbsp;&nbsp;<button onClick={this.closeImportModal} style={cancelButton}>Cancel</button>
              </div>
              </div> 
              
              <div className="modalMessage">{this.state.importModalMessage}</div>
              <br/>
              &nbsp;&nbsp;&nbsp;<input type="checkbox" id="rchecked" onChange={this.handleRecountCheck} defaultChecked={this.state.rchecked}/>&nbsp;<label htmlFor="rchecked">Inventory recount?</label>
		          <br/>
              <p> &gt; <a href={_template} target="_new">download receiver import template</a></p>
             
              </form> 
             </div>                          
            
          </Modal>

          <Modal
            isOpen={this.state.hoursModalIsOpen}
            onAfterOpen={this.afterOpenHoursModal}
            style={customStyles}
            contentLabel="Add Hours"
          >                   
         
            <div className="container-fluid">               
              <form onSubmit={this.handleSubmitHours}>
              <h2>Add Hours</h2>
                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Hours: 
                    </div>
                    <div className="col-sm-8">
                     <input
                        className="form-input"
                        id="rc_hours"
                        name="rc_hours"
                        type="text"
                        value={this.state.rc_hours}
                        onChange={this.handleInputChange}
                        placeholder="" 
                      />
                    </div>                                    
                  </div>

                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Boxes:
                    </div>
                    <div className="col-sm-8">                                    
                     <input
                        className="form-input"
                        id="rc_boxes"
                        name="rc_boxes"
                        type="text"
                        value={this.state.rc_boxes}
                        onChange={this.handleInputChange}
                        placeholder="" 
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Pallets:
                    </div>
                    <div className="col-sm-8">                                    
                      <input
                        className="form-input"
                        id="rc_pallets"
                        name="rc_pallets"
                        type="text"
                        value={this.state.rc_pallets}
                        onChange={this.handleInputChange}
                        placeholder="" 
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Notes:
                    </div>
                    <div className="col-sm-8">                                    
                       <input
                        className="form-input"
                        id="rn_note"
                        name="rn_note"
                        type="text"
                        value={this.state.rn_note}
                        onChange={this.handleInputChange}
                        placeholder="" 
                      />
                    </div>
                  </div>

               <div className="modalMessage">{this.state.hoursModalMessage}</div>
                        <div className="right">
                          <button type="submit">Add</button>&nbsp;&nbsp;&nbsp;<button onClick={this.closeHoursModal} style={cancelButton}>Cancel</button>
                        </div>               
                        
                 
              </form> 

             </div>                          
            
          </Modal>

           <Modal
            isOpen={this.state.statusModalIsOpen}
            onAfterOpen={this.afterOpenStatusModal}
            onRequestClose={this.closeStatusModal}
            style={customStyles}
            contentLabel="Update Status"
          >                   
         
            <div className="container-fluid">               
              <form onSubmit={this.handleSubmitStatus}>
              <h2>Update Status</h2>
                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Status: 
                    </div>
                    <div className="col-sm-8">
                     <select value={this.state.rc_status} 
                                  onChange={(e) => this.handleSelect(e)}>
                            {statusOptions.map((_opt) => <option key={_opt} value={_opt}>{_opt}</option>)}
                          </select>                        
                    </div>                                    
                  </div>

                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Note *:
                    </div>
                    <div className="col-sm-8">                                    
                     <input
                        className="form-input"
                        id="rc_note"
                        name="rc_note"
                        type="text"
                        value={this.state.rc_note}
                        onChange={this.handleInputChange}
                        placeholder="" 
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Email To:
                    </div>
                    <div className="col-sm-8">                
                      <input
                        className="form-input"
                        id="rc_contact_email"
                        name="rc_contact_email"
                        type="text"
                        value={this.state.rc_contact_email}
                        onChange={this.handleInputChange}
                        placeholder="" 
                      /><br/>
                      Separate multiple emails with a comma or semi-colon. 
                      <br/>
                      <input type="checkbox" id="statuschecked" onChange={this.handleStatusEmailCheck} checked={this.state.statusemailchecked}/>&nbsp;<label htmlFor="statuschecked">Send email</label>  <br/>
                          
                    </div>
                  </div>
    
                 

              <div className="modalMessage">{this.state.statusModalMessage}</div>
              <div className="right">
                <button type="submit">Add</button>&nbsp;&nbsp;&nbsp;<button onClick={this.closeStatusModal} style={cancelButton}>Cancel</button>
              </div>               
                        
                 
              </form> 

             </div>                          
            
          </Modal>
            
        <Modal
            isOpen={this.state.photoModalIsOpen}
            onAfterOpen={this.afterOpenPhotoModal}
            onRequestClose={this.closePhotoModal}
            style={customStyles}
            contentLabel="Add Photos"
          >                   
         
            <div className="container-fluid">
              <h2>Add Photos</h2>
                  <div className="row">
                    <div className="col-sm-12">                                      
                      Click button to start camera. <br/>
                      <Webcam
                        audio={false}
                        height={350}
                        ref={this.setRef}
                        screenshotFormat="image/jpeg"
                        width={350}
                        videoConstraints={videoConstraints}
                      />
                      <button onClick={this.handlePhoto}>Capture photo</button>
                    </div>
                                                   
                  </div>

              <div className="modalMessage">{this.state.photoModalMessage}</div>
              <div className="right">
                <button type="submit">Upload</button>&nbsp;&nbsp;&nbsp;<button onClick={this.closePhotoModal} style={cancelButton}>Cancel</button>
              </div> 

             </div>                          
            
          </Modal>

          <Modal
            isOpen={this.state.filesModalIsOpen}
            onAfterOpen={this.afterOpenFilesModal}
            onRequestClose={this.closeFilesModal}
            style={customStyles}
            contentLabel="Add Files"
          >                   
         
            <div className="container-fluid">               
            
              <h2>Add Files</h2>
                  <div className="row">
                    <div className="col-sm-12">                                      
                      
                       <form onSubmit={this.handleAttach}> 
                         <input type="file" id="myFile" name="myFile" /><br/><br/>
                         <input type="submit" value="Upload a file"/>&nbsp;&nbsp;
                         <button onClick={this.closeFilesModal} style={cancelButton}>Cancel</button>
                      </form>                   
                                     
                    </div>
                        
                  </div>

              <div className="modalMessage">{this.state.filesModalMessage}</div>              

             </div>                          
            
          </Modal>


         <Modal
            isOpen={this.state.editModalIsOpen}
            onAfterOpen={this.afterOpenEditModal}
            style={customStyles}
            contentLabel="Edit Receiver"
          >                   
         
            <div className="container-fluid">               
              <form onSubmit={this.handleSubmitEdit}>
              <h2>Edit Receiver</h2>
                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Name/Number:
                    </div>
                    <div className="col-sm-8">
                     <input
                              className="form-input"
                              id="edit_rc_number"
                              name="edit_rc_number"
                              type="text"
                              value={this.state.edit_rc_number}
                              onChange={this.handleInputChange}
                              placeholder="" 
                            />
                    </div>                                    
                  </div>

                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Company:
                    </div>
                    <div className="col-sm-8">                                    
                     <input
                              className="form-input"
                              id="edit_rc_company"
                              name="edit_rc_company"
                              type="text"
                              value={this.state.edit_rc_company}
                              onChange={this.handleInputChange}
                              placeholder="" 
                            />
                    </div>
                  </div>                
                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Expected Date:
                    </div>
                    <div className="col-sm-8">                                    
                      <DateTimePicker
                        disableClock={true}
                        onChange={edit_rc_date_exp => this.setState({ edit_rc_date_exp })}
                        value={this.state.edit_rc_date_exp}                        
                        format={"MM-dd-y h:mm:ss a"}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Max Date:
                    </div>
                    <div className="col-sm-8">                                    
                       <DateTimePicker
                        disableClock={true}
                        onChange={edit_rc_date_max => this.setState({ edit_rc_date_max })}
                        value={this.state.edit_rc_date_max}
                        format={"MM-dd-y h:mm:ss a"}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Received Date:
                    </div>
                    <div className="col-sm-8">                                    
                      <DateTimePicker
                        disableClock={true}
                        onChange={edit_rc_date_receive => this.setState({ edit_rc_date_receive })}
                        value={this.state.edit_rc_date_receive}                        
                        format={"MM-dd-y h:mm:ss a"}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 right">                                      
                      Inspected Date:
                    </div>
                    <div className="col-sm-8">                                    
                      <DateTimePicker
                        disableClock={true}
                        onChange={edit_rc_date_inspect => this.setState({ edit_rc_date_inspect })}
                        value={this.state.edit_rc_date_inspect}                        
                        format={"MM-dd-y h:mm:ss a"}
                      />
                    </div>
                  </div>
                  <br/>
                  <br/>
               <div className="modalMessage">{this.state.editModalMessage}</div>
                        <div className="right">
                          <button type="submit">Update</button>&nbsp;&nbsp;&nbsp;<button onClick={this.closeEditModal} style={cancelButton}>Cancel</button>
                        </div>               
                        
                 
              </form> 

             </div>                          
            
          </Modal>

          <Modal
                            isOpen={this.state.labelModalIsOpen}
                            onAfterOpen={this.afterOpenLabelModal}                            
                            style={printModalStyle}
                            contentLabel="Print Label"
                          >                   
                         <div className="frame">
													    <div className="scroll"> 
                            <div className="container-fluid">               
                              <form onSubmit={this.handleLabelPrint}>
                                  <div className="row">
                                      <div className="col-md-6">            
																				<h3>Print Product Labels</h3>
                                        <div className="form-group" >
                                          <label htmlFor="printQty" className="form-label">Label Qty: </label>&nbsp; 
                                          <input
                                            className="form-input"
                                            id="printQty"
                                            name="printQty"
                                            type="text"
                                            value={this.state.printQty}
                                            onChange={this.handleInputChange}
                                            placeholder="" 
                                          />
                                          <label htmlFor="printSize" className="form-label">Font Size: </label>&nbsp; 
                                          <input
                                            className="form-input"
                                            id="printSize"
                                            name="printSize"
                                            type="text"
                                            value={this.state.printSize}
                                            onChange={this.handleInputChange}
                                            placeholder="" 
                                          />

                                          <label htmlFor="labelHeight" className="form-label">Label Height: </label>&nbsp; 
                                          <input
                                            className="form-input"
                                            id="labelHeight"
                                            name="labelHeight"
                                            type="text"
                                            value={this.state.labelHeight}
                                            onChange={this.handleInputChange}
                                            placeholder="" 
                                          />
                                          <label htmlFor="labelWidth" className="form-label">Label Width: </label>&nbsp; 
                                          <input
                                            className="form-input"
                                            id="labelWidth"
                                            name="labelWidth"
                                            type="text"
                                            value={this.state.labelWidth}
                                            onChange={this.handleInputChange}
                                            placeholder="" 
                                          />
                                        <br/><br/>
                                        <input type="checkbox" id="dchecked" onChange={this.handleDescCheck} defaultChecked={this.state.dchecked}/>&nbsp;<label htmlFor="checked">Add Description to Label</label>  <br/>
                                        <input type="checkbox" id="checked" onChange={this.handleCheck} defaultChecked={this.state.checked}/>&nbsp;<label htmlFor="checked">Print All Receiver Labels</label>  <br/>
                                        Check this option and click "Print".This will print received qty labels for all items. You can change the sizes above. 
		    
                                        </div>
                                        <div className="modalMessage">{this.state.modalMessage}</div>
                                        <div>
                                          <ReactToPrint
                                            trigger={() => <Link to="#"><button type="button" className="btn btn-info btn-sm">Print</button></Link>}
                                            content={() => this.componentRef}
                                          /> &nbsp;&nbsp;

                                          <button className="btn btn-info btn-sm" onClick={this.closeLabelModal}>Cancel</button>
                                        </div> 
                                        <ProductPrintLabel ref={el => (this.componentRef = el)} printAll={this.state.checked} showDesc={this.state.dchecked} allData={this.state.receiveritems} labelHeight={this.state.labelHeight} labelWidth={this.state.labelWidth} printSize={this.state.printSize} printQty={this.state.printQty} pr_sku={this.state.pr_sku} printDesc={this.state.printDesc} printUPC={this.state.printUPC} {...this.props} />
																			
                                      </div>
                                      
                                  </div>
                                 
                              </form> 

                             </div>                          
                             </div>
													  </div>
                          </Modal>
            <Modal
              isOpen={this.state.binModalIsOpen}
              onAfterOpen={this.afterBinOpenModal}                            
              style={customStyles}
              contentLabel="Set Current BIN Location"
            >                   
           
              <div className="container-fluid">               
                <form onSubmit={this.handleBinSubmit}>
                    <div className="row">
                        <div className="col-md-6">            
                          <h3>Update Product BIN</h3>
                          <div className="form-group" >
                            <label className="form-label" ref={subtitle => this.subtitle = subtitle}>Current BIN: </label>&nbsp; 
                            <input
                              className="form-input"
                              id="bn_bin"
                              name="bn_bin"
                              type="text"
                              value={this.state.bn_bin}
                              onChange={this.handleInputChange}
                              placeholder="" 
                            />
                            
                          </div>
                          <div className="modalMessage">{this.state.modalMessage}</div>
                          <div>
                            <button  className="btn btn-info btn-sm" type="submit">Update</button>&nbsp;<button className="btn btn-info btn-sm" onClick={this.closeBinModal} >Cancel</button>
                          </div>               
                          
                        </div>

                        
                    </div>
                   
                </form> 

               </div>                          
              
            </Modal>

            <Modal
              isOpen={this.state.expModalIsOpen}
              onAfterOpen={this.afterExpOpenModal}                            
              style={customStyles}
              contentLabel="Adjust Expected Item Quantity"
            >                   
           
              <div className="container-fluid">               
                <form onSubmit={this.handleSubmitExp}>
                    <div className="row">
                        <div className="col-md-6">            
                          <h3>Adjust Expected Item Quantity</h3>
                          <div className="form-group" >
                            <label htmlFor="ri_qty" className="form-label">CURRENT EXPECTED QTY: <strong>{this.state.ri_qty}</strong> </label><br/>
                            <label htmlFor="adj_qty" className="form-label">SET EXPECTED QTY TO: </label>&nbsp; 
                            <input
                              className="form-input"
                              id="ri_qty"
                              name="ri_qty"
                              type="text"
                              value={this.state.ri_qty}
                              onChange={this.handleInputChange}
                              placeholder="" 
                            />
                            
                          </div>
                          <div className="modalMessage">{this.state.modalMessage}</div>
                          <div>
                            <button className="btn btn-info btn-sm" type="submit">Update</button>&nbsp;<button className="btn btn-info btn-sm" onClick={this.closeExpModal} >Cancel</button>
                          </div>               
                          
                        </div>

                        
                    </div>
                   
                </form> 

               </div>                          
              
            </Modal>

            <Modal
              isOpen={this.state.qtyModalIsOpen}
              onAfterOpen={this.afterQtyOpenModal}                            
              style={customStyles}
              contentLabel="Adjust Received Item Quantity"
            >                   
           
              <div className="container-fluid">               
                <form onSubmit={this.handleSubmitQty}>
                    <div className="row">
                        <div className="col-md-6">            
                          <h3>Adjust Received Item Quantity</h3>
                          <div className="form-group" >
                            <label htmlFor="lg_scan_count" className="form-label">CURRENT RECEIVED ITEM QTY: <strong>{this.state.lg_qty}</strong> </label><br/>
                            <label htmlFor="adj_qty" className="form-label">ADJUST RECEIVED ITEM QTY BY: </label>&nbsp; 
                            <input
                              className="form-input"
                              id="adj_qty"
                              name="adj_qty"
                              type="text"
                              value={this.state.adj_qty}
                              onChange={this.handleInputChange}
                              placeholder="" 
                            />
                            
                          </div>

                          <div className="modalMessage">{this.state.modalMessage}</div>
                          <div>
                            <button ref="btnAdj" className="btn btn-info btn-sm" type="submit" >Update</button>&nbsp;<button className="btn btn-info btn-sm" onClick={this.closeQtyModal} >Cancel</button>
                          </div>               
                          
                        </div>

                        
                    </div>
                   
                </form> 

               </div>                          
              
            </Modal>

        <audio className="good-audio-element">
          <source src={process.env.REACT_APP_ROOT_URL+'/sound/good-noise.mp3'}></source>
        </audio>

        <audio className="bad-audio-element">
          <source src={process.env.REACT_APP_ROOT_URL+'/sound/bad-noise.mp3'}></source>
        </audio>

        <audio className="complete-audio-element">
          <source src={process.env.REACT_APP_ROOT_URL+'/sound/complete-noise.mp3'}></source>
        </audio>





        </div>

                )
    }
}
export default ReceiverContent;
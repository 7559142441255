import React from 'react'
//import { Router, Route } from 'react-router';
import PropTypes from 'prop-types'

import './App.css'
import Admin from './components/Admin'
import SignIn from './components/SignIn'
import SignUp from './components/SignUp'
import ResetPassword from './components/ResetPassword'
import ChangePassword from './components/ChangePassword'
import ForceChangePassword from './components/ForceChangePassword'
import Header from './components/Header'
import Auth from './components/Auth'
import PrivateRoute from './components/PrivateRoute'

//portal pages

//import App from './components/App';
import Clients from './components/Clients';
import Client from './components/Clients/Client';
import MobileProductBin from './components/Mobile/ProductBin';
import MobileOrders from './components/Mobile/Orders';
import MobileOrderDetail from './components/Mobile/OrderDetail';
import MobilePacking from './components/Mobile/Packing';
import MobilePackingDetail from './components/Mobile/PackingDetail';
import ProductBinCheck from './components/Mobile/ProductBinCheck';
//import ProductBinCheckDetail from './components/Mobile/ProductBinCheckDetail';
import ScanProductBin from './components/Scan/ProductBin';
//import ScanOrders from './components/Scan/Orders';
import ScanOrderDetail from './components/Scan/OrderDetail';
//import ScanPacking from './components/Scan/Packing';
import ScanPackingDetail from './components/Scan/PackingDetail';
import ScanProcessingDetail from './components/Scan/ProcessingDetail';

import Orders from './components/Orders';
import OrdersAmazon from './components/Orders/OrderAmazon';
import OrderDetail from './components/Orders/OrderDetail';
import OrderEntry from './components/Orders/OrderEntry';
import OrderPrintDetail from './components/Orders/OrderPrintDetail';
import Pickups from './components/Pickups';
import Products from './components/Products';
import ProductBin from './components/Products/ProductBin';
import ProductCaseMain from './components/Products/ProductCaseMain';
import ProductKit from './components/Products/ProductKit';
import ProductKitMain from './components/Products/ProductKitMain';
import ProductOtherMain from './components/Products/ProductOtherMain';
import ProductIgnoreMain from './components/Products/ProductIgnoreMain';
import ProductReplaceMain from './components/Products/ProductReplaceMain';
import Product from './components/Products/Product';
import Receivers from './components/Receivers';
import ReceiverDetail from './components/Receivers/ReceiverDetail';
import RackPrint from './components/Products/RackPrint';
import TrojanPrint from './components/Products/TrojanPrint';
import Uploader from './components/Upload';
import Clock from './components/Timeclock/Clock';
import ClockList from './components/Timeclock';
import Users from './components/User';
import User from './components/User/User';
import Alerts from './components/Alert';
import Activity from './components/Timeclock/Activity';
import Returns from './components/Returns';
import ReturnDetail from './components/Returns/ReturnDetail';
import Customer from './components/Customer';
import CustomerOrder from './components/Customer/OrderDetail';
import CustomerOrderPrintDetail from './components/Customer/OrderPrintDetail';
import CustomerProducts from './components/Customer/Products';
import CustomerProduct from './components/Customer/Product';
import CustomerPickups from './components/Customer/Pickups';
import CustomerOrderEntry from './components/Customer/OrderEntry';
import CustomerAlerts from './components/Customer/AlertList';
import CustomerReceivers from './components/Customer/Receivers';
import CustomerReceiverDetail from './components/Customer/ReceiverDetail';
import CustomerReturns from './components/Customer/Returns';
import CustomerReturnDetail from './components/Customer/ReturnDetail';
import CustomerProductCaseMain from './components/Customer/ProductCaseMain';
import CustomerProductKit from './components/Customer/ProductKit';
import CustomerProductKitMain from './components/Customer/ProductKitMain';
import CustomerReportOtherStatus from './components/Customer/OtherStatus';
import CustomerReportBilling from './components/Customer/Billing';
import CustomerReportItemBilling from './components/Customer/ItemBilling';
import CustomerProductOtherMain from './components/Customer/ProductOtherMain';
import CustomerProductOtherDetail from './components/Customer/ProductOtherDetail';
import CustomerProductReplaceMain from './components/Customer/ProductReplaceMain';
import CustomerProductReplaceDetail from './components/Customer/ProductReplaceDetail';
import CustomerProductIgnoreMain from './components/Customer/ProductIgnoreMain';
import CustomerProductIgnoreDetail from './components/Customer/ProductIgnoreDetail';
import CustomerInventoryLog from './components/Customer/InventoryLog';
import Reports from './components/Reports';
import Bol from './components/Reports/FindBol';
import Inventory from './components/Reports/Inventory';
import InventoryLog from './components/Reports/InventoryLog';
import Billing from './components/Reports/Billing';
import OtherStatus from './components/Reports/OtherStatus';
import OtherAvail from './components/Reports/OtherAvail';
import ItemBilling from './components/Reports/ItemBilling';
import Fulfillment from './components/Reports/Fulfillment';
import FulfillmentDash from './components/Reports/FulfillmentDash';
//import InventoryLogReport from './components/Reports/InventoryLogReport';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import { state } from 'aws-cognito-redux-saga'


class App extends React.Component {
  static propTypes = {
    auth: PropTypes.object
  } 

  render() {

    const { auth } = this.props   
    
    return (
      <Router>
        <div className="app">
          <div className="screen">
            <Auth />
            <Header />       
            <Switch>             
              <Route path="/signin" component={SignIn} />            
              <Route path="/resetpassword" component={ResetPassword} />
              <Route path="/changepassword" component={ChangePassword} />
              <Route
                path="/forcechangepassword"
                component={ForceChangePassword}
              />
              
              <PrivateRoute path="/signup" component={SignUp} />  
              <PrivateRoute path="/clients" component={Clients} />
              <PrivateRoute path="/client" component={Client} />      
              <PrivateRoute path="/mobile-product-bins" component={MobileProductBin} />  
              <PrivateRoute path="/mobile-orders" component={MobileOrders} />
              <PrivateRoute path="/mobile-order" component={MobileOrderDetail} />
              <PrivateRoute path="/mobile-packing" component={MobilePacking} />
              <PrivateRoute path="/mobile-packing-detail" component={MobilePackingDetail} />
              <PrivateRoute path="/scan-product-bins" component={ScanProductBin} />        
              <PrivateRoute path="/check-product-bins" component={ProductBinCheck} />        
              <PrivateRoute path="/scan-order" component={ScanOrderDetail} />              
              <PrivateRoute path="/scan-packing" component={ScanPackingDetail} />              
              <PrivateRoute path="/scan-processing" component={ScanProcessingDetail} />

              <PrivateRoute path="/orders" component={Orders} />
              <PrivateRoute path="/orders-amazon" component={OrdersAmazon} />              
              <PrivateRoute path="/order" component={OrderDetail} /> 
              <PrivateRoute path="/orderentry" component={OrderEntry} />
              <PrivateRoute path="/orderprintdetail" component={OrderPrintDetail} />
              <PrivateRoute path="/pickups" component={Pickups} />
              <PrivateRoute path="/products" component={Products} />
              <PrivateRoute path="/product-bins" component={ProductBin} />
              <PrivateRoute path="/product-kits" component={ProductKit} />
              <PrivateRoute path="/product-kit" component={ProductKitMain} />
              <PrivateRoute path="/product-case" component={ProductCaseMain} />
              <PrivateRoute path="/product-other" component={ProductOtherMain} />              
              <PrivateRoute path="/product-ignore" component={ProductIgnoreMain} />
              <PrivateRoute path="/product-replace" component={ProductReplaceMain} />
              <PrivateRoute path="/product" component={Product} />
              <PrivateRoute path="/receivers" component={Receivers} />
              <PrivateRoute path="/receiver" component={ReceiverDetail} />
              <PrivateRoute path="/upload" component={Uploader} />
              <PrivateRoute path="/timeclock" component={Clock} />
              <PrivateRoute path="/timeclocklist" component={ClockList} />
              <PrivateRoute path="/users" component={Users} />
              <PrivateRoute path="/alerts" component={Alerts} />
              <PrivateRoute path="/user" component={User} />
              <PrivateRoute path="/activity" component={Activity} />
              <PrivateRoute path="/rackprint" component={RackPrint} />
              <PrivateRoute path="/returns" component={Returns} />
              <PrivateRoute path="/return" component={ReturnDetail} />
              <PrivateRoute path="/customer" component={Customer} />
              <PrivateRoute path="/customer-order" component={CustomerOrder} /> 
              <PrivateRoute path="/customer-orderprint" component={CustomerOrderPrintDetail} />
              <PrivateRoute path="/customer-products" component={CustomerProducts} />
              <PrivateRoute path="/customer-returns" component={CustomerReturns} />
              <PrivateRoute path="/customer-return" component={CustomerReturnDetail} />
              <PrivateRoute path="/customer-product" component={CustomerProduct} />
              <PrivateRoute path="/customer-orderentry" component={CustomerOrderEntry} />
              <PrivateRoute path="/customer-alerts" component={CustomerAlerts} />
              <PrivateRoute path="/customer-receivers" component={CustomerReceivers} />
              <PrivateRoute path="/customer-receiver" component={CustomerReceiverDetail} />
              <PrivateRoute path="/customer-product-kits" component={CustomerProductKit} />
              <PrivateRoute path="/customer-product-kit" component={CustomerProductKitMain} />
              <PrivateRoute path="/customer-product-case" component={CustomerProductCaseMain} />
              <PrivateRoute path="/customer-product-other" component={CustomerProductOtherMain} />
              <PrivateRoute path="/customer-product-replace" component={CustomerProductReplaceMain} />
              <PrivateRoute path="/customer-product-ignore" component={CustomerProductIgnoreMain} />
              <PrivateRoute path="/customer-report-otherstatus" component={CustomerReportOtherStatus} />
              <PrivateRoute path="/customer-report-billing" component={CustomerReportBilling} />
              <PrivateRoute path="/customer-report-item-billing" component={CustomerReportItemBilling} />
              <PrivateRoute path="/customer-inventory-log" component={CustomerInventoryLog} />
              <PrivateRoute path="/customer-pickups" component={CustomerPickups} />
              <PrivateRoute path="/reports" component={Reports} />
              <PrivateRoute path="/reports-inventory" component={Inventory} />
              <PrivateRoute path="/reports-inventory-log" component={InventoryLog} />
              <PrivateRoute path="/reports-billing" component={Billing} />
              <PrivateRoute path="/reports-other-avail" component={OtherAvail} />
              <PrivateRoute path="/reports-other-status" component={OtherStatus} />
              <PrivateRoute path="/reports-bol" component={Bol} />
              <PrivateRoute path="/trojanprint" component={TrojanPrint} />
              <PrivateRoute path="/reports-item-billing" component={ItemBilling} />
              <PrivateRoute path="/reports-fulfillment" component={Fulfillment} />
              <PrivateRoute path="/dash-fulfillment" component={FulfillmentDash} />

              <PrivateRoute path="/*" component={Admin} />
              
              <Route
                path="*"
                render={() =>
                  auth.isSignedIn !== state.AUTH_SUCCESS ? (
                    <Redirect to="/" />
                  ) : (
                    <Redirect to="/SignIn" />
                  )
                }
              />
            </Switch>
          </div>
        </div>
      </Router>
    )
  }
}

export default App

// Header.js
import React, {Component} from 'react';
/*
import matchSorter from 'match-sorter';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import Modal from 'react-modal';
import 'react-dropdown/style.css';
import moment from 'moment';
import { CSVLink } from "react-csv";
*/

class ProductBinCheckDetail extends Component {

    constructor(props) {
        super(props);        

        this.state = {
            upc_search: '',
            upcInfo: '',
            binInfo: '',
            sku_search: typeof(localStorage.getItem('skuSearch')) === 'undefined' ? '' : localStorage.getItem('skuSearch'),
            cl_id: typeof(localStorage.getItem('headerClientId')) === 'undefined' ? 0 : localStorage.getItem('headerClientId'),
            cl_name: '',                       
            pr_id: '', 
            pr_qty: 0,
            bn_bin: '',
            bn_qty: 0,
            bn_order: 0,
            chk_bn_priority: false, 
            productbins: [],
            products: [],            
            userToken: localStorage.getItem('userToken')
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.findUPC = this.findUPC.bind(this);
        //this.handleKeyPress = this.handleKeyPress(this);
        this.handleChange = this.handleChange.bind(this);
        //this.binUPC = this.binUPC.bind(this);
        //this.findTrojanSerial = this.findTrojanSerial.bind(this);
        //this.parseTrojanUPC = this.parseTrojanUPC.bind(this);
        
        this.playGood = this.playGood.bind(this);
        this.playBad = this.playBad.bind(this);
        this.playComplete = this.playComplete.bind(this);
        
    }

    componentWillMount() {      
      //console.log('component will mount');

    }

    componentDidMount() {      
      
      this.getProducts(process.env.REACT_APP_NODE_ROOT_URL+'/products/client/'+this.state.cl_id); 
      
    }
   
    componentDidUpdate(prevProps, prevState) {
      //console.log('component did update');
      //console.log('refresh from update: ' + this.state.refresh);
      //console.log('resp: ' + this.state.resp);
    }

    playGood() {
      const audioEl = document.getElementsByClassName("good-audio-element")[0]
      audioEl.play()
    }
  
    playBad() {
      const audioEl = document.getElementsByClassName("bad-audio-element")[0]
      audioEl.play()
    }

    playComplete() {
      const audioEl = document.getElementsByClassName("complete-audio-element")[0]
      audioEl.play()
    }
       
    getProducts = (url) => {
      fetch(url, {
        method: 'GET',   
        headers: {
        'Accept': 'application/json',
        'bwToken': this.state.userToken
        }      
      }).then(res => res.json())
        .then(products => this.setState({ products }));      
    }
  
    /*
    parseTrojanUPC = () => { 
  
      let barcode = this.state.upc_search.trim(); 
      var regex = /(?<=-).+/;
      //var strToMatch = "180/72K/41-2101007ST+JD201005-2";
      var otherSku = barcode.match(regex);
      console.log('matched: ' + otherSku[0]);
      
      //return true;
      var otherJSON = JSON.stringify({
          cl_id: this.state.cl_id,
          ot_sku: otherSku[0]            
      });

      console.log('getSku: ' + otherJSON)

      return fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/other/sku/'+this.state.cl_id, {
        method: 'post',
        body: otherJSON,      
        headers: {            
        'Content-Type': 'application/json',
        'bwToken': this.state.userToken          
        }
        //TODO:
        //credentials: 'same-origin', // send cookies
        //credentials: 'include'   // send cookies, even in CORS
    
      }).then(res => res.json())
      .then(trojanSerial => this.setState({ trojanSerial }))
      .then(this.findTrojanSerial).catch(function(error) {
        alert(error);      
      });
      
    } 

    findTrojanSerial = () => {
      (async () => {
        let _product = this.state.trojanSerial;
        this.setState({upc_search:_product[0].pr_id})
        await this.findUPC();
      })();   
    }
    */
    /*
    binTrojanSerial = () => {
      (async () => {
        let _product = this.state.trojanSerial;
        this.setState({quick_bin:_product[0].pr_id})
        this.setState({pr_id:_product[0].pr_id})
        await this.binUPC();
      })();   
    }  
    */

    handleChange(event){
      //set upc value searched for
      this.setState({upc_search: event.target.value});
    }

    handleSubmit(event){
      event.preventDefault();    
    }

           
    handleKeyPress = (event) => {

     if(event.key === 'Enter'){
          
          //set upc from search box
          const find_upc = this.state.upc_search.trim();
          console.log("find_upc: " + find_upc);          
          this.findUPC();                        
      }
    }

    findUPC = () => {
      
      const _findupc = this.state.upc_search.trim(); //this.state.upc_search.trim().replace(/^0+/, '');
            
      var loc = _findupc.indexOf("-")

      console.log('upc: ' + _findupc)
      console.log('loc: ' + loc)

      var _allbins = '';

      if (loc === -1) {

        const result = this.state.products.find( product => product.pr_upc === _findupc );

        //if found, then match to bin
        if (result) {
          console.log('result: ' + JSON.stringify(result));
          if (result.all_bins != null) {
            _allbins = result.all_bins;
          }

          this.setState({binMessage:'UPC FOUND - SCAN LOCATION'}); 
          this.setState({upcInfo:'('+result.pr_upc + ') ' + result.pr_sku + ' ' + result.pr_desc }); 
          this.setState({binInfo: 'ALL BINS: ' + _allbins });  
          this.setState({allBins: result.all_bins});
          this.setState({upc_search: ''});          
         
        } else {
          this.setState({upc_search: ''});
          this.playBad();
        }

      } else {

          const allBins = this.state.allBins;

          if (allBins) {
            if (allBins.includes(_findupc)) {
              this.setState({binMessage:'UPC FOUND AT LOCATION'}); 
              this.playGood();
              this.setState({upc_search: ''});
            } else {
              this.setState({binMessage:'UPC NOT FOUND AT LOCATION'}); 
              this.playBad();
              this.setState({upc_search: ''});
            }

          } else {
            this.setState({binMessage:'UPC NOT FOUND AT LOCATION'}); 
            this.playBad();
            this.setState({upc_search: ''});
          }

          /*
          var binJSON = JSON.stringify({           
            bn_bin: _findupc,
            pr_id: _prid
          });

          console.log('find UPC and BIN combo');
          console.log(binJSON);
                  
          fetch(process.env.REACT_APP_NODE_ROOT_URL+'/products/bincheck/'+this.state.cl_id, {
                method: 'post',        
                body: binJSON,      
                headers: {            
                'Content-Type': 'application/json',
                'bwToken': this.state.userToken
            }
                //TODO:
                //credentials: 'same-origin', // send cookies
                //credentials: 'include'   // send cookies, even in CORS
            
            }).then(res => res.json())
            .then(products => this.setState({ products })).then(function(response) {
                //this.playBad();
                //alert('Other products have been found for this location. See select box for results.');                 
                console.log('resp: ' + JSON.stringify(response));
                //window.location.reload();
                //this.setState({refresh:1});            
            }).then(function(data) { 
                //console.log('update bin data on page...');
                console.log('data: ' + JSON.stringify(data));
            }).catch(function(error) {
                alert('Product location could not be added'); 
                console.log('submit err: ' + error);       
            });
        } 
        */
        
        this.setState({upc_search: ''});
                
        return true;
    }
  }

  
    
    render(){
            
        return (
          <div id="main">
            <div className="page-wrapper">
              <div className="row page-titles">
                <div className="col-md-12 align-self-center">
                  <h2 className="text-primary">Product Bin Check</h2> 
                </div>
               
              </div>
              <div className="container-fluid">         
              <div className="card">
              <div className="card-body" name="card-order-list">      
                <div className="row">
                  <div className="col-md-6">
                    <h3>Scan a UPC, then scan a Location verify the bin contents.</h3>
                      <div className="binMessage" style={{fontSize:36}}>{this.state.binMessage}</div>
                      <form id="formUpcSearch" onSubmit={this.handleSubmit}>
                        <input type="text" autoFocus id="upc_search" ref="upc_search" name="upc_search" style={{float:'left',width:300,fontSize:36}} className="upc_search" onKeyPress={this.handleKeyPress} value={this.state.upc_search} onChange={this.handleChange} placeholder="Place cursor here to scan check"/>
                      </form>                      
                      </div>
                    </div> 
                    <div className="upcInfo" style={{fontSize:24}}>{this.state.upcInfo}</div><br/>
                      <div className="binInfo" style={{fontSize:24}}>{this.state.binInfo}</div>               
                  </div>
                </div>
              </div>
              <div id="modalMain">
            </div>
          </div>
        <audio className="good-audio-element">
          <source src={process.env.REACT_APP_ROOT_URL+'/sound/good-noise.mp3'}></source>
        </audio>

        <audio className="bad-audio-element">
          <source src={process.env.REACT_APP_ROOT_URL+'/sound/bad-noise.mp3'}></source>
        </audio>

        <audio className="complete-audio-element">
          <source src={process.env.REACT_APP_ROOT_URL+'/sound/complete.mp3'}></source>
        </audio>

  </div>
                )
    }
}
export default ProductBinCheckDetail;